<template>
  <div class="container-fluid mb-4 custom-calendar">
    <div class="mb-3 row-align-right">
      <v-btn v-if="showPending && isAdmin" color="warning" dark class="mb-2" @click="openPendingEvents()">
        {{ str['pending_events'] }}
      </v-btn>
    </div>
    <v-sheet>
      <div class="custom-calendar-header">
        <v-icon color="default" class="cursor-hover" @click="$refs.calendar.prev()">mdi-chevron-left-circle</v-icon>
        <v-autocomplete
          v-model="type"
          :label="str['type']"
          item-text="label"
          item-value="value"
          :items="types"
          outlined
          autocomplete="new-password"
          :name="Math.random()"
          hide-details
        />
        <v-autocomplete
          v-model="weekday"
          :label="str['weekdays']"
          item-text="label"
          item-value="value"
          :items="weekdays"
          outlined
          autocomplete="new-password"
          :name="Math.random()"
          hide-details
        />
        <v-icon v-if="editPermission" color="success" class="cursor-hover" @click="addEvent">mdi-plus-circle</v-icon>
        <v-icon color="default" class="cursor-hover" @click="$refs.calendar.next()">mdi-chevron-right-circle</v-icon>
      </div>
      <div class="custom-calendar-title">
        <h3>
          {{ $refs.calendar && $refs.calendar.title ? $refs.calendar.title : title }}
        </h3>
      </div>
      <div v-if="showDaysEventsCount" class="labels-background mb-4" v-html="daysEventsCount()"></div>
    </v-sheet>
    <v-sheet>
      <v-calendar
        ref="calendar"
        v-model="value"
        :locale="language"
        :weekdays="weekday"
        :type="type"
        :events="events"
        :event-overlap-mode="mode"
        :event-overlap-threshold="30"
        :event-color="getEventColor"
        @click:event="openEvent"
        @click:date="openDay"
        @change="getEvents"
      ></v-calendar>
    </v-sheet>

    <v-dialog v-if="dialogEvent" v-model="dialogEvent" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ eventToEdit ? str['edit_event'] : str['new_event'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" class="row-flex-align-space">
                <div>
                  <v-btn v-if="eventToEdit && !client" color="blue" class="mr-2" @click="openClient()">
                    {{ str['client'] }}
                  </v-btn>
                  <v-btn v-if="eventToEdit" color="blue" @click="openClientChat()">
                    {{ str['send_message'] }}
                  </v-btn>
                </div>
                <div>
                  <v-btn
                    v-if="eventToEdit && (isAdmin || eventToEdit.status === statusItemsDict.pending.value)"
                    color="error"
                    @click="deleteEvent()"
                  >
                    {{ str['delete'] }}
                  </v-btn>
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col v-for="item in fields" :key="item.id" cols="12">
                <v-text-field v-if="item.type === 'input'" v-model="item.value" :label="item.title" hide-details />
                <v-textarea v-if="item.type === 'textarea'" v-model="item.value" :label="item.title" hide-details />
                <v-autocomplete
                  v-if="item.type === 'select'"
                  v-model="item.value"
                  :label="item.title"
                  item-text="label"
                  item-value="value"
                  :items="item.items"
                  :no-data-text="str['no_data']"
                  hide-details
                  :disabled="item.disabled"
                  :search-input.sync="item.searchInput"
                  @change="item.searchInput = ''"
                />
                <v-checkbox
                  v-if="item.type === 'checkbox'"
                  v-model="item.value"
                  :label="item.title"
                  class="form-field-checkbox"
                  hide-details
                />
                <v-menu
                  v-if="item.type === 'datepicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="item.value" :label="item.title" readonly v-bind="attrs" v-on="on" />
                  </template>
                  <v-date-picker v-model="item.value" style="margin: 0" :locale="datepickerLanguage" @change="item.menu = false" />
                </v-menu>
                <v-menu
                  v-if="item.type === 'timepicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <v-text-field v-model="item.value" :label="item.title" readonly v-bind="attrs" v-on="on" />
                  </template>
                  <v-time-picker v-model="item.value" style="margin: 0" format="24hr" @change="item.menu = false" />
                </v-menu>
                <v-menu
                  v-if="item.type === 'colorpicker'"
                  v-model="item.menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template #activator="{ on, attrs }">
                    <div class="row-flex-align">
                      <label style="margin-right: 10px">
                        {{ item.title }}
                      </label>
                      <div
                        :style="{ backgroundColor: item.value }"
                        style="width: 25px; height: 25px; border-radius: 100%"
                        v-bind="attrs"
                        v-on="on"
                      ></div>
                    </div>
                  </template>
                  <v-color-picker v-model="item.value" style="margin: 0" @change="item.menu = false" />
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closeEventDialog()">
            {{ str['cancel'] }}
          </v-btn>
          <v-btn
            v-if="eventToEdit && (isAdmin || eventToEdit.status === statusItemsDict.pending.value)"
            color="success"
            :disabled="!fieldsValidated()"
            @click="saveEvent()"
          >
            {{ str['save'] }}
          </v-btn>
          <v-btn v-if="!eventToEdit" color="success" :disabled="!fieldsValidated()" @click="saveEvent()">
            {{ str['save'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-if="showPending && isAdmin" v-model="dialogPendingEvents" max-width="750px">
      <v-card>
        <v-card-title>
          <span class="headline">
            {{ str['pending_events'] }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-data-table
                :headers="pendingEventsHeaders"
                :items="pendingEvents"
                :no-data-text="str['no_data']"
                :no-results-text="str['no_data']"
                class="elevation-1"
                style="width: 100%"
              >
                <template #item.employee="{ item }">
                  {{ employeesDict[item.employee] ? employeesDict[item.employee].name : item.employee }}
                </template>
                <template #item.user_id="{ item }">
                  <div class="cursor-hover" @click.stop="openClient(item.user_id)">
                    {{ item.user_id }}
                  </div>
                </template>
                <template #item.actions="{ item }">
                  <v-icon small class="mr-2" color="success" style="font-size: 1.4em" @click.stop="activePendingEvent(item)">
                    mdi-check-circle
                  </v-icon>
                  <v-icon small class="mr-0" color="error" style="font-size: 1.4em" @click.stop="deletePendingEvent(item)">
                    mdi-delete
                  </v-icon>
                </template>
                <template #footer.page-text="{ pageStart, pageStop, itemsLength }">
                  {{ pageStart }}-{{ pageStop }} {{ str['of'] }}
                  {{ itemsLength }}
                </template>
              </v-data-table>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn color="default" @click="closePendingEvents()">
            {{ str['close'] }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Api from '@/services/Api'
import Utils from '@/services/Utils'
import moment from 'moment'

export default {
  name: 'Calendar',
  props: {
    client: Object,
  },
  data() {
    const user = Utils.getUser()
    const showPending =
      user && user.configurations && user.configurations.calendar && user.configurations.calendar.show_pending ? true : false
    const includeFields =
      user && user.configurations && user.configurations.calendar && user.configurations.calendar.fields
        ? user.configurations.calendar.fields
        : []
    const showClientName =
      user && user.configurations && user.configurations.calendar && user.configurations.calendar.show_client_name ? true : false
    const showDaysEventsCount =
      user && user.configurations && user.configurations.calendar && user.configurations.calendar.show_days_events_count ? true : false
    return {
      user: user,
      str: window.strings,
      datepickerLanguage: window.datepickerLanguage,
      language: window.language,
      editPermission: Utils.hasPermission('calendar_edit') ? true : false,
      isAdmin: Utils.hasPermission('calendar_admin') ? true : false,
      statusItems: Utils.getCalendarStatus(),
      statusItemsDict: this.getCalendarStatusDict(),
      showPending: showPending,
      includeFields: includeFields,
      showClientName: showClientName,
      showDaysEventsCount: showDaysEventsCount,
      events: [],
      cacheDates: {
        start: null,
        end: null,
      },
      mode: 'stack',
      type: 'month',
      types: [
        {
          label: window.strings['month'],
          value: 'month',
        },
        {
          label: window.strings['week'],
          value: 'week',
        },
        {
          label: window.strings['day'],
          value: 'day',
        },
        {
          label: window.strings['4day'],
          value: '4day',
        },
      ],
      weekday: [1, 2, 3, 4, 5, 6, 0],
      weekdays: [
        {
          label: window.strings['mon_sun'],
          value: [1, 2, 3, 4, 5, 6, 0],
        },
        {
          label: window.strings['mon_fri'],
          value: [1, 2, 3, 4, 5],
        },
      ],
      activeStatus: 0,
      value: '',
      dialogEvent: false,
      fields: [],
      eventToEdit: null,
      title: '',
      pendingEvents: [],
      dialogPendingEvents: false,
      pendingEventsHeaders: [],
      employeesDict: {},
    }
  },
  beforeMount: function () {
    if (!this.user) {
      return false
    }
    this.setSimpleFields()
    this.setPendingEventsHeaders()
  },
  mounted: function () {
    this.title = this.$refs.calendar.title
  },
  methods: {
    getCalendarStatusDict: function () {
      const list = Utils.getCalendarStatus()
      const dict = {}
      if (list && list.length) {
        list.forEach(function (item) {
          dict[item.status] = item
        })
      }
      return dict
    },
    setSimpleFields: function () {
      const fields = [
        {
          type: 'select',
          id: 'user_id',
          title: window.strings['client'],
          value: '',
          required: true,
        },
        {
          type: 'datepicker',
          id: 'date',
          title: window.strings['date'],
          value: '',
          required: true,
          menu: false,
        },
        {
          type: 'datepicker',
          id: 'start_date',
          title: window.strings['start_date'],
          value: '',
          required: true,
          menu: false,
        },
        {
          type: 'timepicker',
          id: 'start_hour',
          title: window.strings['start_hour'],
          value: '',
          required: true,
          menu: false,
        },
        {
          type: 'datepicker',
          id: 'end_date',
          title: window.strings['end_date'],
          value: '',
          required: true,
          menu: false,
        },
        {
          type: 'timepicker',
          id: 'end_hour',
          title: window.strings['end_hour'],
          value: '',
          required: true,
          menu: false,
        },
        {
          type: 'input',
          id: 'title',
          title: window.strings['title'],
          value: '',
          required: true,
        },
        {
          type: 'textarea',
          id: 'description',
          title: window.strings['description'],
          value: '',
          required: false,
        },
        {
          type: 'colorpicker',
          id: 'color',
          title: window.strings['color'],
          value: '#8fb9d1',
          required: false,
          menu: false,
        },
      ]
      let hasClientField = false
      for (let i = fields.length - 1; i > -1; i--) {
        const index = this.includeFields.indexOf(fields[i].id)
        if (index === -1 || (fields[i].id === 'user_id' && index > -1 && this.client)) {
          fields.splice(i, 1)
          continue
        }
        if (fields[i].id === 'user_id') {
          hasClientField = true
        }
      }
      this.fields = fields
      if (hasClientField) {
        this.getClients()
      }
    },
    setPendingEventsHeaders: function () {
      if (this.showPending) {
        const headers = [
          {
            text: window.strings['employee'],
            value: 'employee',
            align: 'left',
            width: 120,
          },
        ]
        if (!this.client) {
          headers.push({
            text: window.strings['client'],
            value: 'user_id',
            sortable: false,
            align: 'center',
            width: 70,
          })
        }
        headers.push({
          text: window.strings['date'],
          value: 'date',
          sortable: false,
          align: 'center',
          width: 110,
        })
        headers.push({
          text: window.strings['description'],
          value: 'description',
          sortable: false,
          align: 'center',
          width: 250,
        })
        headers.push({
          text: window.strings['actions'],
          value: 'actions',
          sortable: false,
          align: 'center',
          width: 100,
        })
        this.pendingEventsHeaders = headers
      }
    },
    getClients: function () {
      const self = this
      self.$isLoading(true)
      Api.getClients(
        {
          status: this.activeStatus,
          fields: ['id', 'name'],
          useCache: true,
        },
        function (response) {
          const clients = []
          self.$isLoading(false)
          if (response.success) {
            for (let i = 0; i < response.data.length; i++) {
              clients.push({
                value: response.data[i].id,
                label: response.data[i].id + ' - ' + response.data[i].name,
              })
            }
            for (let i = 0; i < self.fields.length; i++) {
              if (self.fields[i].id === 'user_id') {
                self.fields[i].items = clients
                break
              }
            }
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getEvents({ start, end }) {
      const self = this
      let startDate = start.date
      let endDate = end.date
      startDate = moment(startDate).subtract(1, 'days').format('YYYY-MM-DD')
      endDate = moment(endDate).add(1, 'days').format('YYYY-MM-DD')
      if (this.cacheDates.start && this.cacheDates.end && startDate >= this.cacheDates.start && endDate <= this.cacheDates.end) {
        return true
      }
      if (!this.cacheDates.start || (this.cacheDates.start && startDate < this.cacheDates.start)) {
        this.cacheDates.start = startDate
      }
      if (!this.cacheDates.end || (this.cacheDates.end && endDate > this.cacheDates.end)) {
        this.cacheDates.end = endDate
      }
      Api.getCalendarItems(
        {
          start_date: startDate,
          end_date: endDate,
          user_id: this.client ? this.client.id : null,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            const events = self.encodeEvents(response.data)
            const ids = self.events.map((a) => a.id)
            events.forEach(function (item) {
              if (ids.indexOf(item.item_id) === -1) {
                self.events.push(item)
              }
            })
          } else {
            self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    refreshEvents() {
      const self = this
      Api.getCalendarItems(
        {
          start_date: this.cacheDates.start,
          end_date: this.cacheDates.end,
          user_id: this.client ? this.client.id : null,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.events = self.encodeEvents(response.data)
          } else {
            self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    encodeEvents(data) {
      const events = []
      for (let i = 0; i < data.length; i++) {
        const obj = this.encodeEvent(data[i])
        if (obj) {
          events.push(obj)
        }
      }
      return events
    },
    encodeEvent(event) {
      if (event.start_date && event.end_date) {
        const isPending = event.status === this.statusItemsDict.pending.value
        if (isPending && this.user.id !== event.employee) {
          return false
        }
        return {
          pt_id: event.pt_id,
          id: event.item_id,
          user_id: event.user_id,
          status: event.status,
          name:
            (event.user_id && !this.client && this.showClientName ? window.strings['client'] + ' ' + event.user_id + ' - ' : '') +
            ' ' +
            event.title +
            (isPending ? ' (' + window.strings['pending'] + ')' : ''),
          title: event.title,
          start: event.start_date.replace(' ', 'T') + ':00',
          end: event.end_date.replace(' ', 'T') + ':00',
          description: event.description,
          color: isPending ? '#fb8c0052' : event.color,
        }
      } else {
        return false
      }
    },
    getEventColor(event) {
      return event.color
    },
    openDay() {
      this.type = 'day'
    },
    openEvent(evt) {
      if (this.editPermission) {
        this.eventToEdit = evt.event
        this.setData()
        this.openDialog()
      }
    },
    addEvent() {
      if (this.editPermission) {
        const defaultDate =
          this.type === 'day' && this.$refs.calendar && this.$refs.calendar.value
            ? this.$refs.calendar.value
            : moment().format('YYYY-MM-DD')
        this.eventToEdit = null
        for (let i = 0; i < this.fields.length; i++) {
          this.fields[i].disabled = false
          if (this.fields[i].id !== 'color') {
            this.fields[i].value = ''
          }
          if (this.fields[i].id === 'date') {
            this.fields[i].value = defaultDate
          }
          if (this.fields[i].id === 'start_date') {
            this.fields[i].value = defaultDate
          }
          if (this.fields[i].id === 'end_date') {
            this.fields[i].value = defaultDate
          }
        }
        this.openDialog()
      }
    },
    openDialog() {
      this.dialogEvent = true
    },
    fieldsValidated() {
      let dateStart = ''
      let hourStart = ''
      let dateEnd = ''
      let hourEnd = ''
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].required && !this.fields[i].value) {
          return false
        }
        if (this.fields[i].id === 'start_date') {
          dateStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'start_hour') {
          hourStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_date') {
          dateEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_hour') {
          hourEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'date') {
          dateStart = this.fields[i].value
          hourStart = '09:00'
          dateEnd = this.fields[i].value
          hourEnd = '09:00'
          continue
        }
      }
      const startDate = dateStart + ' ' + hourStart
      const endDate = dateEnd + ' ' + hourEnd
      if (startDate > endDate) {
        return false
      }
      return true
    },
    saveEvent() {
      const self = this
      const event = this.getData()
      this.$isLoading(true)
      if (this.eventToEdit) {
        Api.updateCalendarItem(event, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.closeEventDialog()
            self.refreshEvents()
          } else {
            self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        })
      } else {
        event.status = this.isAdmin || !this.showPending ? this.statusItemsDict.active.value : this.statusItemsDict.pending.value
        Api.newCalendarItem(event, function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.closeEventDialog()
            self.refreshEvents()
          } else {
            self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        })
      }
    },
    setData() {
      const start = this.eventToEdit.start.split('T')
      const dateStart = start[0]
      const hourStart = start[1].slice(0, -3)
      const end = this.eventToEdit.end.split('T')
      const dateEnd = end[0]
      const hourEnd = end[1].slice(0, -3)
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id === 'start_date') {
          this.fields[i].value = dateStart
          continue
        }
        if (this.fields[i].id === 'start_hour') {
          this.fields[i].value = hourStart
          continue
        }
        if (this.fields[i].id === 'end_date') {
          this.fields[i].value = dateEnd
          continue
        }
        if (this.fields[i].id === 'end_hour') {
          this.fields[i].value = hourEnd
          continue
        }
        if (this.fields[i].id === 'date') {
          this.fields[i].value = dateStart
          continue
        }
        if (this.fields[i].id === 'user_id') {
          this.fields[i].disabled = this.eventToEdit ? true : false
        }
        if (this.eventToEdit[this.fields[i].id]) {
          this.fields[i].value = this.eventToEdit[this.fields[i].id]
        }
      }
    },
    getData() {
      const event = {}
      let dateStart = ''
      let hourStart = ''
      let dateEnd = ''
      let hourEnd = ''
      for (let i = 0; i < this.fields.length; i++) {
        if (this.fields[i].id === 'start_date') {
          dateStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'start_hour') {
          hourStart = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_date') {
          dateEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'end_hour') {
          hourEnd = this.fields[i].value
          continue
        }
        if (this.fields[i].id === 'date') {
          dateStart = this.fields[i].value
          hourStart = '09:00'
          dateEnd = this.fields[i].value
          hourEnd = '09:00'
          continue
        }
        event[this.fields[i].id] = this.fields[i].value
      }
      if (!hourStart) {
        hourStart = '00:00'
      }
      if (!hourEnd) {
        hourEnd = '23:59'
      }
      event.start_date = dateStart + ' ' + hourStart
      event.end_date = dateEnd + ' ' + hourEnd
      if (this.eventToEdit) {
        event.id = this.eventToEdit.id
        event.user_id = this.eventToEdit.user_id
      }
      if (this.client) {
        event.user_id = this.client.id
      }
      if (!this.showClientName) {
        event.title = window.strings['client'] + ' ' + event.user_id
      }
      if (this.includeFields.indexOf('color') === -1) {
        event.color = '#8fb9d1'
      }
      return event
    },
    deleteEvent() {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          const eventId = self.eventToEdit.id
          self.$isLoading(true)
          Api.deleteCalendarItem(
            {
              user_id: self.eventToEdit.user_id,
              id: eventId,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.closeEventDialog()
                self.refreshEvents()
              } else {
                self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    closeEventDialog() {
      this.dialogEvent = false
      this.eventToEdit = null
    },
    openClient: function (id) {
      const self = this
      const clientId = id ? id : this.eventToEdit.user_id
      if (clientId) {
        this.$isLoading(true)
        Api.getUserWithId(
          {
            id: clientId,
          },
          function (response) {
            self.$isLoading(false)
            self.closeEventDialog()
            if (response.success) {
              const client = Utils.encodeClient(response.data[0])
              Utils.setStorage('client', client)
              window.showPaydayAlert = true
              window.clientPageCacheList = null
              self.$router.navigate.push({
                path: '/home/client',
                query: { tab: 'details' },
              })
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    openClientChat: function () {
      const self = this
      this.$isLoading(true)
      Api.getUserWithId(
        {
          id: this.eventToEdit.user_id,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success && response.data[0]) {
            Utils.openClientChat({
              scope: self,
              client: response.data[0],
            })
          } else {
            self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    openPendingEvents() {
      this.dialogPendingEvents = true
      this.getPendingEvents()
    },
    closePendingEvents() {
      this.dialogPendingEvents = false
    },
    getPendingEvents() {
      const self = this
      this.pendingEvents = []
      this.$isLoading(true)
      Api.getCalendarItems(
        {
          user_id: this.client ? this.client.id : null,
          status: this.statusItemsDict.pending.value,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            const employeesIds = []
            for (let i = 0; i < response.data.length; i++) {
              response.data[i].date = response.data[i].start_date.split(' ')[0]
              employeesIds.push(response.data[i].employee)
            }
            self.pendingEvents = response.data
            self.getEmployees(employeesIds)
          } else {
            self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    getEmployees: function (ids) {
      const self = this
      if (ids && ids.length) {
        Api.getEmployees(
          {
            fields: ['id', 'name'],
            ids: ids,
          },
          function (response) {
            if (response.success) {
              const dict = {}
              response.data.forEach(function (emp) {
                dict[emp.id] = emp
              })
              self.employeesDict = dict
            } else {
              self.$alert(response.message, '', 'warning', Utils.getAlertOptions())
            }
          },
        )
      }
    },
    activePendingEvent(event) {
      const self = this
      Api.updateCalendarItem(
        {
          user_id: event.user_id,
          id: event.item_id,
          status: this.statusItemsDict.active.value,
        },
        function (response) {
          self.$isLoading(false)
          if (response.success) {
            self.refreshPendingEvents(event)
          } else {
            self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
          }
        },
      )
    },
    deletePendingEvent(event) {
      const self = this
      this.$confirm(window.strings['are_you_sure_delete'], '', 'warning', Utils.getAlertOptions(true, true))
        .then(() => {
          self.$isLoading(true)
          Api.deleteCalendarItem(
            {
              user_id: event.user_id,
              id: event.item_id,
            },
            function (response) {
              self.$isLoading(false)
              if (response.success) {
                self.refreshPendingEvents(event)
              } else {
                self.$alert(response.message, window.strings['warning'], 'warning', Utils.getAlertOptions())
              }
            },
          )
        })
        .catch(() => {})
    },
    refreshPendingEvents(event) {
      for (let i = 0; i < this.pendingEvents.length; i++) {
        if (
          this.pendingEvents[i].pt_id === event.pt_id &&
          this.pendingEvents[i].user_id === event.user_id &&
          this.pendingEvents[i].item_id === event.item_id
        ) {
          this.pendingEvents.splice(i, 1)
          break
        }
      }
      this.refreshEvents()
    },
    daysEventsCount() {
      if (this.$refs.calendar && this.$refs.calendar.lastStart && this.$refs.calendar.lastStart.month) {
        const calendarMonth = this.$refs.calendar.lastStart.month
        const formatDate = (date, format) => moment(date).format(format)
        const eventsDaysCount = this.events.reduce((acc, event) => {
          const month = parseInt(formatDate(event.start, 'MM'))
          if (month === calendarMonth) {
            const day = parseInt(formatDate(event.start, 'DD'))
            acc[day] = (acc[day] || 0) + 1
          }
          return acc
        }, {})
        const list = Object.entries(eventsDaysCount)
          .map(([day, count]) => ({ day, count }))
          .sort((a, b) => a.day - b.day)
        let str = ''
        list.forEach((l) => {
          str += `<span><b>${window.strings['day']} ${l.day}</b>:  ${l.count}</span>`
        })
        return str
      }
      return ''
    },
  },
}
</script>
